body {
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}

div {
  scrollbar-width: none;
}

ul {
  scrollbar-width: none;
}